<template>
  <el-affix @scroll="onScroll">
  <header :class="{'head': true, 'shadow-line': active}">
    <div class="header">
      <div class="nav">
        <NuxtLink :to="linkTo('/')" v-if="isDesktop">
          <img class="logo" src="https://static.ppkao.com/www/images/logo.png">
        </NuxtLink>
        <div class="menu">
          <Tabs v-model="activeIndex"
                :tabs="menus"
                :disable-slide="disableSlide"
                :transform="false">
            <template #default="{ item }">
              <NuxtLink :to="linkTo(item.url)"
                        :class="{ active: isActive(item.url) }">
                <h3 v-if="item.text">{{ item.text }}</h3>
                <img v-else-if="item.img" v-if="isDesktop" :src="item.img" />
              </NuxtLink>
            </template>
          </Tabs>
        </div>
      </div>
      <div class="user-info">
        <ClientOnly fallback-tag="div">
          <LazyTheme size="small"/>
        </ClientOnly>
        <template v-if="store.userInfo.isLogin">
          <ClientOnly fallback-tag="div">
            <el-popover
              :effect="effect"
              :width="260"
              trigger="click"
              @before-enter="() => getUser()"
              popper-class="user-popover"
            >
              <template #reference>
                 <img class="avatar" :src="store.userInfo.UserFace"/>
              </template>
              <div class="info-box">
                <span class="uid">UID：{{ store.userInfo.UserID }}
                  <CopySvg class="copy" @click="copy(store.userInfo.UserID)"/>
                </span>
                <span class="num">搜题次数：{{ store.userInfo.SearchPackNum }}
                    <RefreshSvg @click="() => getUser()" :class="{'loading': loading, refresh: true}" />
                </span>
                <ul>
                  <li v-for="item in userAction" :key="item.title">
                    <template v-if="item.exit">
                      <el-popconfirm title="是否退出登录?"
                                     placement="top"
                                     confirm-button-text="退出"
                                     cancel-button-text="取消"
                                     @confirm="handleLogout"
                      >
                        <template #reference>
                          <a style="cursor: pointer">
                            <img :src="`https://static.ppkao.com/www/images/${item.icon}`">
                            <span>{{ item.title }}</span>
                          </a>
                        </template>
                      </el-popconfirm>
                    </template>
                    <a v-else :href="!item.exit && !item.dialog ? item.url : 'javascript:void(0)'"
                        :target="!item.exit && !item.dialog ? '_blank' : '_self'"
                        @click="item.payment && ( modalStore.setModal('pay', true))"
                        style="cursor: pointer">
                      <img :src="`https://static.ppkao.com/www/images/${item.icon}`">
                      <span>{{ item.title }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </el-popover>
          </ClientOnly>
        </template>
        <template v-else>
          <template v-if="isMobileOrTablet">
            <ClientOnly fallback-tag="a">
            <a :href="loginUrl" class="no-login">登录</a>
            </ClientOnly>
          </template>
          <div v-else v-wave class="no-login" @click="handleLogin">登录</div>
        </template>

      </div>
    </div>
  </header>
  <ClientOnly>
    <el-dialog v-model="showModal"
               destroy-on-close
               width="380px"
               :show-close="false"
               center>
      <img class="close" @click="showModal = false" src="https://static.ppkao.com/www/images/icon-close.png"/>
      <LoginBox
        :show-icon="false"
        class="login-box"
        :login-success="() => showModal = false"/>
    </el-dialog>
  </ClientOnly>
  </el-affix>
</template>

<script setup>
import { getUserInfo } from '~/api';
import CopySvg from '@/assets/svgs/copy.svg';
import RefreshSvg from '@/assets/svgs/refresh.svg'

const modalStore = useModalStore();
const store = useUserStore();
const { isDesktop, isMobileOrTablet } = useDevice();
const menus =isMobileOrTablet ? mobileHomeNavs : homeNavs; // 导航列表
const userAction = isMobileOrTablet ? userMobileToolBar : userToolBar; // 操作toolbar
const showModal = ref(false);
const loginUrl = ref('https://h5.ppkao.com/#/login');

// if (process.client) {
//   useHead({
//     script: [{
//       src: ' https://unpkg.com/vconsole@latest/dist/vconsole.min.js',
//       async: true,
//       defer: true,
//       crossorigin: 'anonymous',
//       onload() {
//         var vConsole = new window.VConsole();
//       }
//     }]
//   })
// }

const loading = ref(false); // 刷新次数
const nuxtApp = useNuxtApp();
const route = useRoute();
const disableSlide = computed(() => route.name === 'ai'); // 隐藏滑块
const isDark = computed(() => nuxtApp.$darkMode?.isDarkMode?.value); // 是否暗夜模式
const active = ref(false); // 滚动显示阴影
const isActive = (url, path = route.path) => {
    if (process.client) {
      if (route.path !== location.pathname) path = location.pathname;
    }
   return url === '/' ? path === url : typeof path === 'string' && path.startsWith(url);
};
const activeIndex = ref(menus.findIndex(item => isActive(item.url))) // 当前选中的索引

/**
 * 获取用户信息
 */
const getUser = (params) => {
  const cookieValue = getCookie('QZ_KSUser');
  const {UserID, UserToken} =  params || parseUserToken(cookieValue)
  loading.value = true;
  getUserInfo({UserID, UserToken})
    .then(res => {
      if (res.S === '1') {
        store.setUserInfo(res.UserList[0]);
      }
    })
    .finally(() => {
      setTimeout(()=>{
        loading.value = false;
      }, 400)
    });
}

const copy = async (uid) => {
  try {
    await navigator.clipboard.writeText(uid);
    ElMessage.success('复制成功')
  } catch (err) {
    ElMessage.success(err.message)
  }
}
console.log('[登录参数]:', route.query)
if (process.client) {
  loginUrl.value = `https://h5.ppkao.com/#/login?redirect_url=${window.location.href}`;
  const { UserID, UserToken } = route.query;
  if (getCookie('QZ_KSUser')) {
    getUser();
  } else if (UserID && UserToken) {
    getUser({ UserID, UserToken });
  }
}

watch(
  () => route.path,
  (newPath) => {
    activeIndex.value = menus.findIndex(item => isActive(item.url, newPath));
  }
);
const effect = computed(() => isDark.value ? 'dark' : 'light');

// 回到顶部滚动
const onScroll = (value) => {
  active.value = value.scrollTop >= 10;
}
const handleLogin = () => {
  showModal.value = true;
}
// 退出登录
const handleLogout = async () => {
  await logout(false);
}

</script>
<style lang="scss">
.user-popover {
  border-radius: 28px !important;
}
</style>
<style scoped lang="scss">
.close {
  position: absolute;
  right: -50px;
  top: 0;
  width: 38px;
  cursor: pointer;
}
:deep(.el-dialog) {
  padding: 0;

}
.login-box {
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.info-box {
  @include flex-col-center;
  .uid {
    user-select: text;
    @include font(var(--primary-text-color), 18px, 600);
    text-align: center;
    margin-top: 6px;
    margin-bottom: 4px;
    @include flex-y-center;
    .copy {
      width: 1em;
      cursor: pointer;
      fill: var(--primary-color);
    }
  }
  .num {
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    .refresh {
      @include font(var(--primary-color), 12px);
      text-decoration: underline;
      cursor: pointer;
      margin-left: 6px;
      width: 16px;
      height: 16px;
      fill: #0479FF;
    }
    .loading {
      animation: spin 500ms linear infinite;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      display: block;
      height: 100%;
      left: 50%;
      top: 0;
      width: 1px;
      background: linear-gradient(180deg, var(--toolbar-line));
    }
    &::before {
      position: absolute;
      content: "";
      display: block;
      left: 0;
      top: 50%;
      margin-top: -1px;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, var(--toolbar-line));
    }
    li {
      a {
        @include flex-col-center;
        padding: 18px 28px;
        span {
          @include font(var(--primary-text-color), 12px);
          margin-top: 10px;
        }

      }
    }
  }
}
.shadow-line {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
}
.head {
  padding: 0 var(--head-x-padding);
  height: var(--head-height);
  background-color: var(--card-bg-color);
  @include flex-center();
  .header {
    height: 100%;
    @include flex-bet-center ();
    width: var(--web-wdith);
    .nav {
      height: 100%;
      @include flex-y-center();
      .logo {
        cursor: pointer;
        margin-right: 76px;
      }
      .menu {

        position: relative;
        height: 100%;
        .active {
          color: var(--primary-color);
        }
        :deep(.category-tab) {
          color: var(--title-color);
        }
      }
    }
  }
  .user-info {
    @include flex-y-center();
    .avatar {
      width: var(--avatar-size);
      border-radius: 50%;
      cursor: pointer;
    }

    .no-login {
      user-select: none;
      cursor: pointer;
      @include circle(var(--avatar-size));
      background: var(--login-box-color);
      @include font(var(--login-text-color), 12px, 600);
      @include flex-center();
    }
  }
}
</style>

